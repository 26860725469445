<template>
  <div class="home">
    <v-progress-linear :active="getLoaderStatus" indeterminate fixed top color="primary" height="5px" style="z-index: 500;"></v-progress-linear>
    <top-nav-bar :mini="mini"></top-nav-bar>
    <mice-navigation @vendor-list-mini="minimizeList"></mice-navigation>
    <listing @drawer="drawer" :mini="mini"></listing>
    <v-main :class="{'detail-content-margin': !listingDrawer}" :style="[!listingDrawer ? { 'padding-left': '0px' } : { 'padding-left': '232px' }]" class="details-content">
      <div class="d-flex chat-content-height">
        <div class="chat-body-style">
          <chat-body></chat-body>
        </div>
        <div class="chat-recipient-style">
          <recipient></recipient>
        </div>
      </div>
    </v-main>
  </div>
</template>

<script>
const TopNavBar = () => import(/* webpackChunkName: "top-nav-bar" */'@/components/TopNavBar');
const Listing = () => import(/* webpackChunkName: "chat-listing" */'./chat/Listing');
const ChatBody = () => import(/* webpackChunkName: "chat-body" */'./chat/Body');
const Recipient = () => import(/* webpackChunkName: "chat-recipient" */'./chat/Recipient');
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'RequestIndex',
  components: { TopNavBar, Listing, ChatBody, Recipient },
  data: () => ({
    mini: false,
    listingDrawer: true,
  }),
  mounted() {
    this.loadRequests();
  },
  computed: {
    ...mapGetters(['getLoaderStatus']),
  },
  methods: {
    minimizeList(status) {
      this.mini = status;
    },
    drawer(value) {
      this.listingDrawer = value;
    },
    ...mapActions(['loadRequests']),
  },
}
</script>
<style scoped>
.details-content {
  margin-left: 480px;
  height: 100vh;
  background-color: white;
}
.detail-content-margin {
  margin-left: 233px;
}
.chat-content-height {
  /*height: calc(100vh - 50px);*/
}
.chat-body-style {
  width: 70%;
  /*overflow-y: scroll;*/
  /*position: relative;
  overflow-x: hidden*/
}
.chat-recipient-style {
  width: 30%;
  overflow-y: scroll;
}
</style>
